<template>
  <div>
    <!-- 协议管理 -->
    <div class="content-wrap">
      <div class="btn-wrap cfx">
        <el-button type="success" size="small" icon="el-icon-plus" @click="handleForm(false)" v-if="OA.includes('service:hall:add')">添加协议</el-button>
      </div>

      <!--列表-->
      <paged-table :data="bHallList" :total="bHallTatol" :loading="tLoading" :isShowSelection="false" defaultSortProp="id" v-model="queryParams" @refresh="refresh"> 
        <el-table-column sortable="custom" prop="extend.schoolName" show-overflow-tooltip label="学校" width="250"></el-table-column>
        <el-table-column sortable="custom" prop="serviceType" show-overflow-tooltip label="标题">
          <template slot-scope="scope">
            <span v-if="scope.row.serviceType==1">运维客服</span>
            <span v-else-if="scope.row.serviceType==2">移动客服</span>
            <span v-else-if="scope.row.serviceType==3">联通客服</span>
            <span v-else>电信客服</span>
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="contactType" show-overflow-tooltip label="内容">
          <template slot-scope="scope">
            <span v-if="scope.row.contactType==1">手机</span>
            <span v-else-if="scope.row.contactType==2">座机</span>
            <span v-else-if="scope.row.contactType==3">微信</span>
            <span v-else>QQ</span>
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="remark" show-overflow-tooltip label="备注" align="center"></el-table-column>
        <el-table-column sortable="custom" prop="addTime" show-overflow-tooltip label="创建时间"></el-table-column>
        <el-table-column sortable="custom" prop="extend.addUserName" show-overflow-tooltip label="操作者"></el-table-column>
        <el-table-column align="right" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleDetails(scope.row)" icon="el-icon-search" title="详情"></el-button>
            <el-button size="mini" type="primary" @click="handleForm(true, scope.row,afterFun)" icon="el-icon-edit" title="编辑" v-if="OA.includes('service:customer:editor')" ></el-button>
            <el-button size="mini" type="danger" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除" v-if="OA.includes('service:customer:del')"></el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑营业厅管理' : '添加营业厅管理'" :visible.sync="aVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="120px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item  label="选择学校：" prop="schoolId">
          <el-select  v-model="form.schoolId" @change="fetchSchoolDicts" clearable filterable placeholder="请选择学校">
              <el-option 
              v-for="(item,index) in schoolAll"
              :label="item.schoolName" 
              :value="item.id"
              :key="index"
              ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item  label="选择校区：" prop="distId">
          <el-select   v-model="form.distId" clearable filterable placeholder="请选择校区">
              <el-option 
              v-for="(item,index) in schoolDicts"
              :label="item.distName" 
              :value="item.id"
              :key="index"
              ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="营业大厅名称：" prop="name">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="地址：" prop="address">
          <el-input v-model="form.address" clearable></el-input>
        </el-form-item>
        <el-form-item label="运营商：" prop="operator">
          <el-input v-model="form.operator" clearable></el-input>
        </el-form-item>
        <el-form-item label="是否启用：" prop="status">
          <el-select v-model="form.status" clearable filterable placeholder="请选择启用状态">
            <el-option label="停用" :value="0"></el-option>
            <el-option label="启用" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input type="textarea" v-model="form.remark" clearable :row="3"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>

    <!-- 详情界面 -->
    <el-dialog title="用户详情" :visible.sync="bVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="100px" ref="detailsFileds" :model="details" size="small">
        <el-form-item label="学校名称：" prop="extend.schoolName">
          <span v-text="details.extend.schoolName"></span>
        </el-form-item>
        <el-form-item label="名称：" prop="name">
          <span v-text="details.name"></span>
        </el-form-item>
        <el-form-item label="地址：" prop="address">
          <span v-text="details.address"></span>
        </el-form-item>
        <el-form-item label="运营商：" prop="operator">
          <span v-text="details.operator"></span>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <span v-text="details.remark"></span>
        </el-form-item>
        <el-form-item label="创建时间：" prop="addTime">
          <span v-text="details.addTime"></span>
        </el-form-item>
        <el-form-item label="操作者：" prop="extend.updUserName">
          <span v-text="details.extend.updUserName"></span>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import BaseUpload from "@/components/common/BaseUpload";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'treatyManege',
  components: {
    PagedTable,
    BaseUpload
  },
  mixins: [pageMixin, provinces],
  data() {
    return {
      queryParams: {
        name: null,
      },
      userRoleIds:[],
      roleOptions:[],
      // 所有学校
      schoolAll:[],
      // 所有校区
      schoolDicts:'',
      form:{
        id:'',
        schoolId:'',
        distId:'',
        name:'',
        address:'',
        operator:'',
        remark:'',
        status:'',
      },
      details:{
        extend:{},
        name:'',
        operator:'',
        remark:'',
        addTime:'',
      },
      formRules:{
        userName: [{ required: true, message: '请输入用户账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入用户密码', trigger: 'blur' }],
        realName: [{ required: true, message: '请输入用户姓名', trigger: 'blur' }],
      },

      pageApi:'bizHallList',
      insertApi:'bizHallAdd',
      editorApi:'bizHallEdit',
      deleteApi:'bizHallDelete',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("serviceHall", ["bHallList", "bHallTatol"]),
  },
  methods: {
    ...mapActions("broadBand",["getSchoolDicts", "getSchoolAll",]),
    ...mapActions("serviceHall",["bizHallList", "bizHallAdd", "bizHallEdit", "bizHallDelete"]),
    // 获取学校
    async fetchSchoolAll() {
        const res = await this.getSchoolAll({})
        this.schoolAll = res
        console.log(res,"xuexiao");
    },
    // 获取校区
    async fetchSchoolDicts(val) {
        console.log(val)
        const res = await this.getSchoolDicts({schoolId:val})
        this.schoolDicts = res
        console.log(res,"xuexiao");
    },
    afterFun(row) {
      console.log(row)
      this.fetchSchoolDicts(row.schoolId)
    },
    async submitForm(api) {
      let params = Object.assign({}, this.form)
      this.$refs.formFileds.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交保存吗？', '提示', {}).then(async () => {
            this.bLoading = true
            try {
              if(this.isEditor){
                delete params.userName
                delete params.mobile
                delete params.password
              }
              await this[api](params)
              this.$message.success('数据保存成功！')
              this.aVisible = false
              this.refresh()
            }catch(e){
              log(e)
            }finally {
              this.bLoading = false
            }
          })
        }
      })
    },
    
  },
  async mounted() {
    if(this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()
    this.fetchSchoolAll()
  }
};
</script>